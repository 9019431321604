import React from 'react';
import { graphql } from 'gatsby';
import { SignupForm } from '@mangoart/gatsby-ui/components/ezagrar/SignupForm';
import DefaultLayout from '../../layout/DefaultLayout';

const RegisterPage = ({ data, location }) => {
  return <DefaultLayout>{/* <SignupForm /> */}</DefaultLayout>;
};

export default RegisterPage;

export const RegisterPageQuery = graphql`
  query RegisterPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
  }
`;
